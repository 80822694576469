.backBtn {
    transition: all 0.3s ease-in-out;
    z-index: 99;
    &--left {
        position: absolute;
        top: 0.5rem;
    }
    &__sticky {
        bottom: 1rem;
        transition: all 0.5s ease-in-out;
        position: fixed;
        transform: translateY(100px);
        left: 6rem;
        &--stuck {
            transition: all 0.3s ease-in-out;
            transform: translateY(0);
        }
        @include media-breakpoint-down(xs) {
            left: 3rem;
            transform: scale(0.9);
            bottom: 2rem;
        }
    }
    &--home {
        display: none;
    }
}
