@import "sass/partials/_variables.scss";
// #darkmodePreloader {
//     position: fixed;
//     pointer-events: none;
//     width: 100vw;
//     height: 100vh;
//     background: rgba(0, 0, 0, 0.5);
//     z-index: 999;
//     opacity: 0;
//     text-align: center;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     &:before {
//         position: fixed;
//         top:50%;
//         color: grey;
//         font-size: 2rem;
//         text-align: center;
//         content: "Darkmode: ON";
//     }
// }

// Btn
$darkmodewidth: 5rem;
.darkmodecontainer {
    position: fixed;
    z-index: 9999;
    cursor: pointer;
    bottom: 0.5rem;
    left: 0;
    &__darkmode {
        transition: transform 0.2s ease-in-out !important;
        &:hover {
            transform: scale(1.1);
        }
        position: relative;
        bottom: 0.7rem;
        right: auto;
        left: 0.7rem;
        &__toggler {
            width: 50%;
            height: 100%;
            position: absolute;
            border-radius: $border-radius;
        }
    }
    @include media-breakpoint-down(xs) {
        // top: 2rem;
        // bottom: auto;
        // left: 50%;
        // transform: translateX(-50%);
        // position: absolute;
        transform: rotate(-90deg) scale(0.9);
        bottom: 1.5rem;
        left: 0.5rem;
    }
}
.darkmodeTime {
    position: absolute;
    bottom: 0;
    left: 0;
}

body.darkmodeOn {
    background: $body-bg-darkmode;
    transition: all 0.2s ease-in-out;
    & .form-control {
        background: transparent;
        color: inherit;
        border-color: rgb(83, 83, 83);
        &:focus {
            border-color: $primary;
        }
    }
    & .portfolioItemContainer {
        & h1,
        h2,
        .h1,
        .h2 {
            color: inherit;
        }
    }
    & .homeCarousel .multiCarousel__item {
        // border: 1px solid $primary;
        // box-shadow: 0px 0px 20px $primary;
        transition: all 0.2s ease-in-out !important;
        &:hover {
            transition: all 0.2s ease-in-out !important;
        }
    }
    & h1 {
        color: white;
    }
    & .portfolioItem {
        filter: brightness(80%);
    }
    & .darken {
        filter: brightness(70%);
    }
    color: rgba(255, 255, 255, 0.781);
    // & #darkmodePreloader {
    //     animation: darkmodePreloaderAnimation 0.5s forwards;
    // }
    &:after {
        // animation: fadeInBgDarkmode 0.2s forwards ease-in-out;
        opacity: $body-bg-opacity-darkmode;
    }

    @keyframes fadeInBgDarkmode {
        100% {
            opacity: $body-bg-opacity-darkmode;
        }
    }
    & .navbar-light .navbar-toggler.collapsed .icon {
        color: white;
    }
    &:before {
        opacity: 0 !important;
        animation: none;
    }
    & .social {
        color: white;
    }
    // Buttons
    & .btn {
        color: white;
        &-transparent {
            color: white;
            &-primary {
                color: $primary;
                & * {
                    fill: grey;
                }
            }
        }
        &-light {
            color: black;
        }
        &-primary {
            // background: linear-gradient(to right, #30cfd0 0%, #330867 100%);
            border-color: transparent;
            box-shadow: 2px 100px 1px #000 inset;
            background-origin: border-box;
            background-clip: content-box, border-box;
            background-image: linear-gradient(
                    rgba(255, 255, 255, 0),
                    rgba(255, 255, 255, 0)
                ),
                $primaryGradient;
            &:hover {
                color: white;
                border-color: transparent;
                background-image: linear-gradient(
                        rgba(255, 255, 255, 0),
                        rgba(255, 255, 255, 0)
                    ),
                    $primaryGradient;
                box-shadow: 0px 0px 0px #000 inset;
            }
        }
        &-secondary {
            box-shadow: 0px 0px 0px black inset;
            &:hover {
                box-shadow: 2px 100px 1px black inset;
                color: white;
            }
        }
    }
    // & .homeCarousel .react-multi-carousel-item,
    // & .portfolioItemDetails {
    //     filter: brightness(90%);
    // }

    & .homeCarousel {
        & .react-multi-carousel-item {
            & .multiCarousel__item {
                border: 1px solid rgba(255, 255, 255, 0.2);
                box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.171);
            }
        }
    }
    // & .portfolioItem {
    //     filter: drop-shadow(0px 0px 0px shade($secondary, 10%)) brightness(70%);
    //     &:hover {
    //         @include media-breakpoint-up(sm) {
    //             filter: drop-shadow(10px 10px 0px shade($primary, 60%))
    //                 brightness(90%);
    //         }
    //     }
    // }
    & .hamburger {
        & span {
            background: white;
            &:before,
            &:after {
                background: white;
            }
        }
    }
    & .navTopBg {
        background: rgb(56, 56, 56);
    }
    & .navBottom {
        background: rgba(rgb(31, 22, 22), 0.98) !important;
        box-shadow: 10px 0px 15px rgba(black, 0.8);
        // border-top: 1px solid rgba(white, 0.2);
    }
    & .backBtn .btn,
    & .scrollTop .btn {
        color: $primary;
        background: $primaryDark;
        &:hover {
            box-shadow: none;
        }
    }
    & .preloader {
        background: black;
        opacity: 100;
        &__img {
            background-image: url("https://cdn.sanity.io/images/23x9eh58/production/d7a5a7d30ee4edd411e0944ad9ff8f024644d9c3-200x200.gif?h=200&fit=max");
        }
    }
}
body:not(.darkmodeOn) {
    transition: all 0.2s ease-in-out;
}
@keyframes darkmodePreloaderAnimation {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 100;
    }
    60% {
        opacity: 100;
    }
    100% {
        opacity: 0;
    }
}
