* {
    border: 0;
    box-sizing: inherit;
    -webkit-font-smoothing: auto;
    font-weight: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
    text-decoration: none;
    text-rendering: optimizeLegibility;
}
// Bootstrap
@import "partials/variables"; // Bootstrap overrides
@import "~bootstrap/scss/bootstrap";

// Partials
@import "partials/mixins";
@import "partials/typography";
@import "partials/bootstrapAddons";

// Boostrap addons
@import "partials/buttons";
@import "partials/typography";
@import "partials/tags";

// Components
@import "components/nav";
@import "components/darkmode";
@import "components/portfolio";
@import "components/preloader";
@import "components/scrollTop";
@import "components/back";

user-select-none {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
::-webkit-scrollbar {
    display: none;
}
@include media-breakpoint-up(md) {
    .container {
        max-width: 900px;
    }
}

.subtle-shadow {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}
