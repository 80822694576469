// Emoji font
@font-face {
    font-family: "color-emoji";
    src: local("Apple Color Emoji"), local("Segoe UI Emoji"),
        local("Segoe UI Symbol"), local("Noto Color Emoji");
}
// Emojis
.emoji {
    font-family: "color-emoji";
}

// Typography
html {
    font-size: 17px;
}
h1 {
    margin-block-end: 1em;
    font-weight: bold;
    // & .gradient {
    //     background: $primaryGradient;
    //     -webkit-background-clip: text;
    //     -webkit-text-fill-color: transparent;
    // }
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
b,
i {
    color: inherit;
}
.paragraph {
    max-width: 35rem;
}
.quote {
    max-width: 25rem;
}
.text-primary {
    color: $primary;
}
.heading {
    @include media-breakpoint-down(xs) {
        font-size: 2.5rem;
        hyphens: auto;
        max-width: 14ch;
        margin-bottom: 1.5rem;
        line-height: 2.7rem;
    }
    @include media-breakpoint-up(xs) {
        font-size: 3rem;
    }
}

.t-center {
    text-align: center;
}
@media screen and (min-width: 2500px) {
    html {
        font-size: 20px;
    }
}
.author {
    font-weight: normal;
    opacity: 0.8;
    & pre {
        letter-spacing: 0.1rem;
        text-transform: uppercase;
        font-size: 0.7rem;
    }
    @include media-breakpoint-down(xs) {
        & pre {
            font-size: 0.6rem;
        }
    }
}
