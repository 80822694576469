::selection {
    opacity: 0.4;
    color: white;
    background: black;
}
body {
    position: relative;
    overflow-x: hidden;
    max-width: 100vw;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    &:not(.darkmodeOn) {
        background: white;
    }
    &:before,
    &:after {
        content: "";
        opacity: 0;
        position: fixed;
        width: 100vw;
        height: 100vh;
        transition: all 0.3s ease-in-out;
        left: 0;
        top: 0;
        z-index: -1;
    }
    &:after {
        filter: invert(1) hue-rotate(200deg);
        background: $body-bgImg-darkmode $body-bg-position / $body-bg-size
            no-repeat;
        @include media-breakpoint-down(sm) {
            background: $body-bgImg-mobile-darkmode $body-bg-position-mobile /
                $body-bg-size-mobile no-repeat;
        }
    }
    &:before {
        background: $body-bgImg $body-bg-position / $body-bg-size no-repeat;
        opacity: $body-bg-opacity;
        // animation: fadeInBg 0.3s forwards ease-in-out 0.3s;
        @include media-breakpoint-down(sm) {
            background: $body-bgImg-mobile $body-bg-position-mobile /
                $body-bg-size-mobile no-repeat;
        }
    }
}
@keyframes fadeInBg {
    100% {
        opacity: $body-bg-opacity;
    }
}
#root {
    max-width: 100vw;
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;
}
.bodyBg {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: -1;
}
svg.svg-inherit * {
    fill: currentColor !important;
}
svg.svg-inherit-stroke * {
    stroke: currentColor !important;
    stroke-width: 0.5rem;
    fill: transparent !important;
}
a:hover {
    text-decoration: none;
}
.vertical-divider {
    &:before {
        content: "";
        display: block;
        margin: auto;
        border-radius: 0.3rem;
        background: rgba(150, 150, 150, 0.5);
        position: absolute;
        top: 50%;
        left: 50%;
        height: 50%;
        width: 0.2rem;
        transform: translate(-50%, -50%);
    }
    position: relative;
}
vr {
    &:before {
        content: "";
        display: block;
        margin: auto;
        border-radius: 0.3rem;
        background: rgba(150, 150, 150, 0.5);
        position: absolute;
        top: 50%;
        left: 50%;
        height: 50%;
        width: 50%;
        transform: translate(-50%, -50%);
    }
    position: relative;
    height: 100%;
    display: block;
    width: 0.2rem;
    margin: auto;
}
