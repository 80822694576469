button:focus {
    outline: 0;
    box-shadow: none;
}
.dark {
    & .btn-transparent {
        color: $body-bg;
    }
}
.hoverScale {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    transition: all 0.15s ease-in-out;
    &:hover {
        transform: scale(0.9);
    }
}
.btn {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    border-width: 0.1rem;
    font-weight: bold;
    box-shadow: 2px 0px 0px inherit inset;
    padding: 0.5rem 1.5rem;
    transition: all 0.2s cubic-bezier(0.6, 0.2, 0.05, 1);
    -webkit-font-smoothing: subpixel-antialiased;
    transform: perspective(1px) translateZ(0) scale(0.95);
    backface-visibility: hidden;
    &:hover {
        transform: perspective(1px) translateZ(0) scale(1);
    }
}
.btn-cta {
    @include button-variant($cta, $cta);
    &:not(:hover) {
    }
}
.btn-outline-cta {
    @include button-variant(transparent, $cta);
    color: tint($cta, 50%);
}
.btn-outline-primary {
    color: $body-color;
    box-shadow: 0px 2px 0px $primary inset;
    border-color: $primary;
    background-origin: border-box;
    background-clip: content-box, border-box;
    background: transparent;
    &:hover {
        color: white;
        box-shadow: 100px 100px 1px $primary inset;
    }
}
.btn-outline-secondary {
    color: $body-color;
    box-shadow: 0px 2px 0px $secondary inset;
    border-color: $secondary;
    background-origin: border-box;
    background-clip: content-box, border-box;
    background: transparent;
    &:hover {
        color: white;
        box-shadow: 100px 100px 1px $secondary inset;
    }
}
.btn-primary {
    // background: linear-gradient(to right, #30cfd0 0%, #330867 100%);
    box-shadow: 0px 0px 0px #fff inset;
    border-color: transparent;
    background-origin: border-box;
    background-clip: content-box, border-box;
    background-image: linear-gradient(
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0)
        ),
        $primaryGradient;
    &:hover {
        color: black;
        border-color: transparent;
        background-image: linear-gradient(
                rgba(255, 255, 255, 0),
                rgba(255, 255, 255, 0)
            ),
            $primaryGradient;
        box-shadow: 2px 100px 1px #fff inset;
    }
}

.btn-outline-grey {
    box-shadow: 0px 2px 1px rgba(122, 122, 122, 0.178) inset;
    border-color: transparent;
    background-origin: border-box;
    background-clip: content-box, border-box;
    background: transparent;
    &:hover {
        color: black;
        border-color: transparent;
        box-shadow: 100px 100px 1px rgba(122, 122, 122, 0.178) inset;
    }
    border: 0.1rem solid rgba(122, 122, 122, 0.178);
}
.btn-transparent {
    /* bg, border, hover bg, hover border*/
    @include button-variant(
        transparent,
        transparent,
        rgba(200, 200, 200, 0.2),
        transparent
    );
    color: $body-color;
    &-primary {
        @extend .btn-transparent;
        color: $primary;
        background-origin: border-box;
        background-clip: content-box, border-box;
        background: transparent;
        &:hover {
            border-color: $primary;
            background: transparent;
        }
    }
    &-secondary {
        @extend .btn-transparent;
        @include button-variant(
            transparent,
            transparent,
            $secondary,
            transparent
        );
        color: $secondary;
    }
    &-grey {
        @extend .btn-transparent;
        @include button-variant(transparent, transparent, grey, transparent);
        color: grey;
    }
}
.btn-light {
    @include button-variant($body-bg, transparent);
}
