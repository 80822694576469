@include media-breakpoint-down(xs) {
    #navTopCollapse {
        // background: linear-gradient(0deg, rgba(50, 50, 50, 1), transparent 90%);
        // background: red;
        // transition: all 0.1s ease-in-out;
        // &.show {
        //     transition: all 0.1s ease-in-out;
        //     background: black;
        // }
        // &.collapse:not(.show) {
        //     opacity: 0;
        //     transition: all 0.1s ease-in-out;
        // }
        // &.show {
        //     opacity: 1;
        //     transition: all 0.1s ease-in-out;
        // }
    }
    #navTop {
        position: relative;
        z-index: 2;
    }
    #navBottom {
        position: relative;
        z-index: 4;
    }
    .navTopBg {
        position: fixed;
        pointer-events: none;
        &:hover {
            cursor: zoom-out;
        }
        top: 0;
        left: 0;
        z-index: -1;
        width: 100vw;
        height: 100vh;
        opacity: 0;
        background: black;
        transition: all 0.3s ease-in-out;
        &-show {
            pointer-events: all;
            opacity: 0.8;
            transition: all 0.3s ease-in-out;
        }
        & .navbar-toggler-icon {
            display: none;
        }
    }
}
.navbar-dark {
    & .btn {
        color: $body-bg;
    }
}
.navbar-light .navbar-toggler:not(:hover) {
    color: inherit;
}
.navbar-light .navbar-toggler:not(.collapsed) .icon {
    color: $body-bg;
}

.navTop__close {
    text-align: center;
    margin-top: 10rem;
}

.hamburger--toggled {
    & span {
        background: white;
    }
}

.navBottom {
    background: $primaryLight;
    @include media-breakpoint-down(xs) {
        padding: 1rem 0 0.8rem 0 !important;
        & .btn {
            padding: 0.6rem 1.5rem;
        }
    }
}
