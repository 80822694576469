@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "../partials/mixins";
@import "../partials/variables";

@include media-breakpoint-up(xs) {
    .multiCarousel {
        &__item {
            object-fit: cover;
            height: 225px;
        }
    }
}
@include media-breakpoint-up(sm) {
    .multiCarousel {
        &__item {
            object-fit: cover;
            height: 250px;
        }
    }
}
@include media-breakpoint-up(lg) {
    .multiCarousel {
        &__item {
            object-fit: cover;
            height: 300px;
        }
    }
}
.multiCarousel__item {
    object-position: top;
}

// @keyframes fadeInCarousel {
//     0% {
//         opacity: 0;
//     }
//     100% {
//         opacity: 1;
//     }
// }
// @keyframes slideInCarousel {
//     0% {
//         transform: translateX(3rem);
//     }
//     100% {
//         transform: translateX(0);
//     }
// }

.homeCarousel {
    // animation: fadeInCarousel 0.5s forwards ease-in-out 1s,
    //     slideInCarousel 1s forwards ease-in-out 1s;
    // opacity: 0;
    @include media-breakpoint-up(md) {
        transform: scale(1) translateX(5rem);
        &:hover {
            transform: scale(1.02) translateX(5rem);
        }
    }
    // mask-image: linear-gradient(
    //     90deg,
    //     rgba(255, 255, 255, 1) 35%,
    //     rgba(255, 255, 255, 0) 80%
    // );
    // -webkit-mask-image: linear-gradient(
    //     90deg,
    //     rgba(255, 255, 255, 1) 35%,
    //     rgba(255, 255, 255, 0) 80%
    // );
    // &:hover {
    //     mask-image: linear-gradient(
    //         90deg,
    //         rgba(255, 255, 255, 1) 0%,
    //         rgba(255, 255, 255, 1) 100%
    //     );
    //     -webkit-mask-image: linear-gradient(
    //         90deg,
    //         rgba(255, 255, 255, 1) 0%,
    //         rgba(255, 255, 255, 1) 100%
    //     );
    // }
    transition: all 0.2s ease-in-out;
    & .react-multi-carousel-item {
        background: grey;
        border-radius: 1rem;
        transform: scale(0.9);
        & .multiCarousel__item {
            // border: 1px solid rgba(0, 0, 0, 0.2);
            box-shadow: 0px 0px 25px rgba(0, 17, 255, 0.1);
        }
    }
    & .multiCarousel__icon {
        position: absolute;
        top: 0.5rem;
        z-index: 1;
        font-size: 2rem;
        right: 1rem;
        // transform: translate(50px, -50px);
        transition: all 0.2s cubic-bezier(0.6, 0.2, 0.05, 1);
    }
    & .multiCarousel__text {
        position: absolute;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        bottom: 0.5rem;
        left: 1.2rem;
        z-index: 1;
        @include media-breakpoint-down(md) {
            font-size: 1.2rem;
            max-width: 10ch;
        }
        @include media-breakpoint-down(xs) {
            display: none;
        }
    }
    @include media-breakpoint-up(lg) {
        width: 100vw;
        & .react-multi-carousel-item {
            transition: all 0.4s ease-in-out !important;
            & * {
                transition: transform 0.4s ease-in-out !important;
            }
        }
        & .react-multi-carousel-item--active {
            opacity: 1;
            // margin-right: -2rem;
            & ~ .react-multi-carousel-item--active {
                // transform: scale(0.8);
                // opacity: 0.5;
                // margin-right: -3.5rem;
                // & .multiCarousel__item {
                //     transform: scale(0.9);
                // }
            }
        }
        &:hover {
            & .react-multi-carousel-item {
                transition: all 0.2s cubic-bezier(0.6, 0.2, 0.05, 1) !important;
                margin-right: 0;
                & * {
                    transition: all 0.2s cubic-bezier(0.6, 0.2, 0.05, 1) !important;
                }
            }
            & .multiCarousel__item {
                // box-shadow: none !important;
            }
            &
                .react-multi-carousel-item.react-multi-carousel-item--active:not(:hover) {
                transform: scale(0.8) !important;
                filter: brightness(80%);
                & .multiCarousel__item {
                    // box-shadow: 5px 5px 0px rgba(150, 150, 150, 0.3) !important;
                }
                opacity: 0.5;
                & ~ .react-multi-carousel-item--active {
                    transform: scale(0.95);
                    &:hover {
                        opacity: 1;
                        margin-right: 0;
                    }
                }
            }
        }
    }
    & .multiCarousel__item {
        object-position: top;
        min-height: 50vh;
        @include media-breakpoint-down(xs) {
            align-items: flex-start;
            & svg {
                padding-top: 4rem;
            }
        }
    }
}
