$preloadersize: 200px;
.preloader {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    color: $primary;
    left: 0;
    z-index: 999999;
    pointer-events: none;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &__svg {
        width: 10rem;
        margin-bottom: 2rem;
    }
    &__img {
        width: $preloadersize;
        height: $preloadersize;
        margin-bottom: 2rem;
        background: url("https://cdn.sanity.io/images/23x9eh58/production/30a2be4f2e63e582affb49851b2b63046e78e977-200x200.gif?h=200&fit=max")
            center center / 100% no-repeat;
    }
    &__text {
        font-weight: bold;
    }
    animation: fadeOutPreloader 0.3s forwards ease-in-out 0.3s;
}

@keyframes fadeOutPreloader {
    100% {
        opacity: 0;
    }
}
#logo {
    width: 10rem;
    fill: $primaryLight;
    margin-bottom: 2rem;
    stroke: $primary;
    stroke-width: 0.2rem;
}
#logo path:nth-child(1) {
    stroke-dasharray: 480;
    stroke-dashoffset: 480;
    animation: line 1s alternate infinite;
}
#logo path:nth-child(2) {
    stroke-dasharray: 210;
    stroke-dashoffset: 210;
    animation: line 1s alternate infinite;
}

@keyframes line {
    100% {
        stroke-dashoffset: 0;
    }
}
