// Colors
$primary: #ff6055;
$primaryDark: #310b08;
$primaryLight: #ffdfdc;
$secondary: #394bf1;
$secondaryLight: #f5d3ff;
$secondaryDark: #3a0933;
$primaryGradient: linear-gradient(-45deg, $secondary 0%, $primary 100%);

$cta: #fc7638;
$info: rgb(139, 160, 216);
$body-color: #13151a;
$body-bg: #ffffff;
$body-bg-darkmode: rgb(20, 22, 26);

// Body background
$body-bg-opacity: 0.2;
$body-bg-opacity-darkmode: 0.2;
$body-bg-position: center center;
$body-bg-position-mobile: center center;
$body-bg-size: cover;
$body-bg-size-mobile: 200vh;
// $samebg: url("https://cdn.sanity.io/images/23x9eh58/production/fc56a8fd334c008c3ee333c76aac35623de88f7f-3866x4832.png?w=1920&h=1080&fit=crop&fm=jpg&q=65");
$samebg-mobile: url("https://cdn.sanity.io/images/23x9eh58/production/fc56a8fd334c008c3ee333c76aac35623de88f7f-3866x4832.png?w=1080&h=1920&fit=crop&fm=jpg&q=65");
$samebg: url("https://cdn.sanity.io/images/23x9eh58/production/8b34b606bdacc2875e943500597109c76673a45b-2726x4084.png?fm=jpg&w=2000&q=65");
$body-bgImg: $samebg;
$body-bgImg-mobile: $samebg-mobile;
$body-bgImg-darkmode: $samebg;
$body-bgImg-mobile-darkmode: $samebg-mobile;

// Borders
$border-width: 0.2rem;
$border-radius: 0.7rem;

// Typography
$font-size-base: 1rem;

// @font-face {
//     font-family: "Proxima Soft";
//     src: local("Proxima Soft"),
//         url(../../constants/fonts/proximasoft-regular-webfont.woff)
//             format("woff"),
//         url(../../constants/fonts/proximasoft-regular-webfont.woff2)
//             format("woff2");
//     font-weight: normal;
//     font-style: normal;
// }
// @font-face {
//     font-family: "Proxima Soft";
//     src: local("Proxima Soft"),
//         url(../../constants/fonts/proximasoft-bold-webfont.woff) format("woff"),
//         url(../../constants/fonts/proximasoft-bold-webfont.woff2)
//             format("woff2");
//     font-weight: bold;
//     font-style: normal;
// }
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap");

$font-family-sans-serif: "Nunito", system-ui, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-serif: Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace: Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;
$headings-font-family: $font-family-sans-serif;
$btn-focus-box-shadow: 0px 0px 0px 5px yellow;

:export {
    bodyBgImg: $body-bgImg;
    borderWidth: $border-width;

    // Use in react
    primaryColor: $primary;
    primaryDarkColor: $primaryDark;
    primaryLightColor: $primaryLight;

    ctaColor: $cta;
    bodyColor: $body-color;
    bodybgColor: $body-bg;
    infoColor: $info;
    secondaryColor: $secondary;
    secondaryLightColor: $secondaryLight;
    secondaryDarkColor: $secondaryDark;
}
