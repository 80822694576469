// @include media-breakpoint-up(md) {
//     .portfolioItems:hover {
//         & .portfolioItem {
//             &:hover {
//                 transform: scale(1.1) translate(-25px, -25px);
//                 box-shadow: 5px 10px 0px $primary;
//                 border-color: $primary;
//                 filter: drop-shadow(10px 10px 0px shade($primary, 10%));
//                 & * {
//                 }
//                 & .portfolioItem__nav {
//                     margin-left: -3rem;
//                     margin-right: -3rem;
//                 }
//                 & .portfolioItem__img {
//                     transform: scale(1.05) translate(-15px, -15px);
//                 }
//                 & .portfolioItem__svg {
//                     transform: scale(1.1) translate(-5px, -5px);
//                     filter: drop-shadow(1px 1px 0px shade($primary, 10%));
//                 }
//                 & svg:not(.portfolioItem__svg) > * {
//                     fill: currentColor !important;
//                 }
//                 & h2 {
//                     border-bottom: 1px solid $primary;
//                 }
//                 & .btn-primary:not(.btn-outline-primary) {
//                 }
//                 & .badge {
//                     padding-left: 1rem;
//                     padding-right: 1rem;
//                 }
//             }
//         }
//     }
// }
.portfolioItemContainer {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include media-breakpoint-up(md) {
        padding: 10px 5vh;
    }
    @include media-breakpoint-down(md) {
        padding: 10px;
    }
}
.portfolioListThumbnail {
    @include media-breakpoint-up(md) {
        max-height: 300px;
        object-fit: cover;
        overflow: hidden;
    }
}
.portfolioItem {
    box-shadow: 5px 5px 0px rgba(150, 150, 150, 0.1);
    border: 1px solid rgba(150, 150, 150, 0.4);
    transition: filter 0.15s cubic-bezier(0.6, 0.2, 0.05, 1);
    overflow: hidden;
    transform: scale(1);
    filter: drop-shadow(0px 0px 0px 0px shade($primary, 10%));
    &:hover {
        @include media-breakpoint-up(sm) {
            filter: drop-shadow(10px 10px 0px $primary);
        }
    }
    & h2 {
        display: inline;
        line-height: 2rem;
    }
    &__img,
    &__svg,
    &__nav,
    & .badge,
    & h2 {
        transition: all 0.15s cubic-bezier(0.6, 0.2, 0.05, 1);
        transform-origin: bottom right;
    }
}
.portfolioItemDetails {
    padding-bottom: 8rem;
    box-shadow: 5px 5px 0px rgba(150, 150, 150, 0.1);
}
.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
    &_column {
        padding-left: 30px; /* gutter size */
        background-clip: padding-box;
    }
}
.portfolioGrid {
    &--grid {
        padding: 0 5rem;
        @include media-breakpoint-down(xs) {
            padding: 0 1rem;
        }
    }
}
.progress-icon {
    position: fixed;
    z-index: 99999;
    bottom: 0.6rem;
    left: 50%;
    right: 50%;
    margin: auto;
    width: 3rem;
    & path {
        stroke: $primary;
    }
}
.search input {
    background: transparent;
    border-color: inherit;
    &:focus {
        border-color: $primary;
        background: transparent;
        color: $primary;
        &::placeholder {
            color: $primary;
            opacity: 0;
        }
    }
}
