.scrollTop {
    position: fixed;
    bottom: 1rem;
    right: 0.5rem;
    z-index: 99;
    transition: all 0.5s ease-in-out;
    transform: translateY(100px);
    &--stuck {
        transition: all 0.3s ease-in-out;
        transform: translateY(0);
        @include media-breakpoint-down(xs) {
            bottom: 2rem;
            transform: scale(0.9);
        }
    }
    &--home {
        display: none;
    }
}
